import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Logo from './logo.png';
import ListGroup from 'react-bootstrap/ListGroup';
import axios from "axios";
import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import moment from 'moment';

const Validate = () => {

    let { folioNum, tokenNum } = useParams();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [folio, setFolio] = useState(folioNum);
    const [token, setToken] = useState(tokenNum);
    const [status, setStatus] = useState({status: null, message: null});

    console.log('Data')
    const getData = () =>{
        axios
            .post('https://backprevengo.paymun.mx/public/api/validate-cert', {
                folio: folio,
                token: token,
            })
            .then((response) => {
                setStatus(response.data);
                setData(response.data.data);
            });
    }

    const handleChange = (e) =>{
        setFolio(e.target.value);
    }

    useEffect(()=>{
        if(folioNum){
            getData();
        }
    },[])

    const getDiffInDays = () =>{
        var a = moment(data.fecha_termino);
        var b = moment(data.vigencia_fecha);

        if(a.isSameOrBefore(b)){
            const diff = b.diff(a, 'days');
            return diff;
        }

        return '0';
    }


  return (
    <>
      <div className="container">
        <div className="row card my-5 p-4">
            <div className="col-12 col-lg-10 mx-auto">
                <div className="header text-center">
                    <img src={Logo} className="img-fluid mx-auto"/>
                    <h3>
                        Validacion de Certificados
                    </h3>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-8">
                        <label htmlFor="exampleInputEmail1">Ingrese numero de control</label>
                        <input type="text" className="form-control"
                               placeholder="No. de control" value={folio}
                               onChange={handleChange}
                        />
                    </div>
                    <div className="col-4 mt-auto">
                        <button type="submit" className="btn btn-primary w-100" onClick={getData}>Buscar</button>
                    </div>
                </div>
                {
                    status.status === "success" ?
                        <>
                            <hr/>
                            <h4>Resultados de la búsqueda</h4>
                            <div className="row text-center">
                                <div className="col-12 py-4">
                                    <h2>Curso</h2>
                                    <h2 className="fw-bold">
                                        {data.nombre_curso}
                                    </h2>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <h4>Folio</h4>
                                    <p>{data.folio_unico_registro}</p>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <h4>Nombre</h4>
                                    <p>{data.nombre_cursante}</p>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <h4>Fecha de termino</h4>
                                    <p>{data.fecha_termino}</p>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <h4>Fecha de expiracion</h4>
                                    <p>{data.vigencia_fecha}</p>
                                </div>
                                <div className="col-6 mx-auto">
                                    <h4>Estatus</h4>
                                    {
                                        getDiffInDays() > 0 ?
                                            <button className="btn button btn-success w-100">
                                                Valido
                                            </button> :
                                            <button className="btn button btn-danger w-100">
                                                Expirado
                                            </button>
                                    }

                                    <Badge bg="secondary">{getDiffInDays()} dias restantes</Badge>
                                </div>
                            </div>
                        </> :""
                }
                {
                    status.status === "error" ?
                        <div className="alert alert-danger mt-4" role="alert">
                            {status.message}
                        </div>
                        : ""
                }
            </div>
        </div>
      </div>
    </>
  )
};

export default Validate;